import { TweenMax, Power2, TimelineLite } from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap";
import "imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js";

if ($(".top-work").length) {
  var controller = new ScrollMagic.Controller();

  var scene_hero = new ScrollMagic.Scene({
    triggerElement: ".top-work",
    triggerHook: "onEnter",
    // duration: 800,
    offset: 0,
  })
    // .addIndicators()
    .addTo(controller);

  scene_hero.on("enter", () => {
    //triggerElementを過ぎたとき
    $(".c-contact-fixed").addClass("is-show");
  });
  scene_hero.on("leave", () => {
    //triggerElementを抜けたとき
    $(".c-contact-fixed").removeClass("is-show");
  });
}
