$('span.ajax-loader').hide();


document.addEventListener( 'wpcf7submit', function( event ) {
	switch ( event.detail.status ) {
		case 'wpcf7c_confirmed':
		$('body').addClass('wp-contactform7-confirm-page');
		$("input[type=radio]:not(:checked)").closest('.wpcf7-list-item').hide();

		$("input[name=inquiry_mail_confirm]").closest('.sw-form__emailItem').hide();
		break;
	}
}, false );

document.addEventListener( 'wpcf7mailsent', function( event ) {
  location = '/service_thanks/'; /* 遷移先のURL */
}, false );
$('.wpcf7c-btn-back').on('click', function(){
	$('body').removeClass('wp-contactform7-confirm-page');
	$("input[type=radio]:not(:checked)").closest('.wpcf7-list-item').show();
	$("input[name=inquiry_mail_confirm]").closest('.sw-form__emailItem').show();
});
