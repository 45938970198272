import smoothScroll from '../../plugins/smooth-scroll.js';

//アンカーリンクの場合data-scrollをつける
$("a[href*='#']").each(function(){
  var href = $(this).attr('href');
  if(href && href !== "#") {
    var id = href.substring(href.indexOf("#"),href.length);
    if(($(id).length)){
      // console.log(href)
      $(this).attr('data-scroll', '');
    }
  }
});
//data-scrollがついているものはスムーススクロール

//別ページからのアンカーリンク対応
var winWidth = $(window).width();
var topMargin = winWidth >= 767 ? 116 : 0;


var hash = location.hash;
// console.log(hash);
var target = $(hash);
if(target.length) {
    // $('html,body').animate({ scrollTop: 0 }, '0');
    var position = $(target).offset().top - topMargin;
    $(window).on('load', function(){
        // $('html,body').show();
        $("html, body").animate({scrollTop:position}, 0, "swing");
    });
}

smoothScroll.init({
  speed: 500,
  offset: $(window).width() >= 769 ? 116 : 0
});
