var agent = navigator.userAgent;
if(agent.search("iPhone") != -1){
  $('body').addClass('ua-iphone');
}
if(agent.search("iPad") != -1){
  $('body').addClass('ua-ipad');
}
if(agent.search("Android") != -1){
  $('body').addClass('ua-android');
}
