const modal = (() => {
  class Modal {
    constructor() {
      this.body = document.querySelector('body');
      this.targets = document.querySelectorAll('.js-modal');
      this.modalContents = document.querySelectorAll('[data-modal]');
      this.scrollPosition = "";
      this.openFlag = "";
      this.modalOpenTarget = "";
    }

    init() {
      for (let target of this.targets) {
        target.addEventListener('click', (e) => {
          this.modalOpenTarget = e.target.closest('.js-modal').getAttribute('data-modal-target');
          this.setFlag(this.modalOpenTarget);
          this.show();
        })
      }
      this.clickOutside();

    }

    setScrollPosition() {
      this.scrollPosition = window.scrollY
    }

    setFlag(openTarget) {
      // this.openFlag = true
      if (this.body.classList.contains('js-modal-close')) {
        this.body.classList.toggle('js-modal-close')
        document.querySelector(`[data-modal="${openTarget}"]`).classList.toggle('js-modal-target-close');
      }
      if (this.body.classList.contains('js-modal-open')) {
        this.body.classList.toggle('js-modal-close')
        document.querySelector(`[data-modal="${openTarget}"]`).classList.toggle('js-modal-target-close');
      }
      this.body.classList.toggle('js-modal-open')
      document.querySelector(`[data-modal="${openTarget}"]`).classList.toggle('js-modal-target-open');
      this.openFlag = this.body.classList.contains('js-modal-open') ? true : false
    }

    show() {
      if (this.openFlag) {
        this.scrollPosition = window.scrollY
        const openStyle = new Map([
          ["position", "fixed"],
          ["top", -1 * this.scrollPosition + "px"],
          ["height", "100%"]
        ])
        // $("#js-modal").fadeIn(250);
        // for (const [key, value] of openStyle) {
        //   this.body.style[key] = value;
        // }
      } else {
        const closeStyle = new Map([
          ["position", "static"],
          ["overflow", "visible"],
          ["height", "auto"]
        ]);
        // $("#js-modal").fadeOut(250);
        // for (const [key, value] of closeStyle) {
        //   this.body.style[key] = value;
        // }
        // window.scrollTo(0, this.scrollPosition);
      }
    }

    clickOutside() {
      for (let modalContent of this.modalContents) {
        modalContent.addEventListener('click', (e) => {
          if (this.modalOpenTarget = e.target.getAttribute('data-modal')) {
            this.setFlag(this.modalOpenTarget);
            this.show();
            console.log(e.target.getAttribute('data-modal'))
          }
        })
      }
    }
  }
  return new Modal();
})();

export default modal;


